import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    tiers: []
}

const getters = {
    allTiers: (state) => state.tiers
}

const actions = {
    async getTiers ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee_tier`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                commit('SET_TIERS',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createTier ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
            'commission' : formData.commission,
            'is_percent' : formData.unit == '%' ? true : false
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `employee_tier`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getTiers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateTier ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
            'commission' : formData.commission,
            'is_percent' : formData.unit == '%' ? true : false
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `employee_tier/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getTiers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteTier ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `employee_tier/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getTiers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    }

}

const mutations = {
    SET_TIERS : (state, payload) => {
        state.tiers = payload
      }
}

export default {
    state,
    getters,
    actions,
    mutations
}
