const XLSX = require('excel4node');

const state = {
    loading: false,
    saving: false,
    alert_state: false,
    alert_message: '',
    dataTableLoader : false,
}

const getters = {
    loadingState : (state) => state.loading, // Loading Modal
    savingState : (state) => state.saving, 
    alertState : (state) => state.alert_state, 
    alertMessage : (state) => state.alert_message, 
    loadingDataTable : (state) => state.dataTableLoader
}

const b64toBlob = function (text_b64) {

    var byteString = atob(text_b64);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}

const createXLSXJson = function (data_json, header) {
    var new_header = [];
    var new_data = [];
    var new_data_buff = {};

    for (var key in header) {
      new_header.push(key);
      new_data_buff[key] = header[key];
    }

    new_data.push(new_data_buff);

    for (var i = 0; i < data_json.length; i++) {
      new_data_buff = {};

      for (var _key in header) {
        new_data_buff[_key] = data_json[i][_key] || "";
      }

      new_data.push(new_data_buff);
    }

    return {
      data_json: new_data,
      header: new_header
    };
  }

const actions = {
    setLoading({ commit }, status) {
        commit('SET_LOADING',status);
    },
    setSaving({ commit }, status) {
        commit('SET_SAVING',status);
    },
    setAlert({ dispatch }, setting ) {
        dispatch('setAlertState', setting.status );
        dispatch('setAlertMessage', setting.message );
    },
    setAlertState({ commit }, status) {
        commit('SET_ALERT',status);
    },
    setAlertMessage({ commit }, message) {
        commit('SET_ALERT_MESSAGE',message);
    },
    async genExcelFile({ commit }, export_config) {
    
        let { inp_data_json, inp_header, col_width, sheet_name } = export_config;

        console.log("sheet_name", sheet_name)
        if (!(Array.isArray(inp_header) == Array.isArray(col_width) && Array.isArray(inp_header) == Array.isArray(sheet_name)))
        {
            console.log("inp_header, col_width and sheet_name must be array or not array indentically");
            return false;
        }
    
        if (!Array.isArray(sheet_name))
        {
            inp_data_json = [inp_data_json];
            inp_header = [inp_header];
            col_width = [col_width];
            sheet_name = [sheet_name];
            
        }

    
        try
        {
            let wb = new XLSX.Workbook();
    
            var style_header = wb.createStyle({
                font: { color: '#000000', size: 12, bold: true },
                fill: { bgColor : '#DDDDDD', type : "none" },
                border: { // §18.8.4 border (Border)
                    left: { style: 'medium', color: '#000000' },
                    right: { style: 'medium', color: '#000000' },
                    top: { style: 'medium', color: '#000000' },
                    bottom: { style: 'medium', color: '#000000' }
                }
            });
            var style_data = wb.createStyle({
                font: { color: '#000000', size: 12, },
                border: { // §18.8.4 border (Border)
                    left: { style: 'thin', color: '#000000' },
                    right: { style: 'thin', color: '#000000' },
                    top: { style: 'thin', color: '#000000' },
                    bottom: { style: 'thin', color: '#000000' }
                }
            });
    
            for (let wb_idx = 0 ; wb_idx < inp_data_json.length ; wb_idx++)
            {
                let ws = wb.addWorksheet(sheet_name[wb_idx]);
                let { data_json, header } = createXLSXJson(inp_data_json[wb_idx], inp_header[wb_idx]);
    
                for ( let row_idx = 1 ; row_idx <= data_json.length ; row_idx++ )
                {
                    for ( let col_idx = 1 ; col_idx <= header.length ; col_idx++ )
                    {
                        ws.cell(row_idx, col_idx)
                        .string(data_json[row_idx-1][header[col_idx-1]].toString())
                        .style( row_idx == 1 ? style_header : style_data );
                    }
                    
    
                }
    
                for ( let col_idx = 1 ; col_idx <= header.length ; col_idx++ )
                {
                    if (col_width[wb_idx][header[col_idx-1]])
                    {
                        ws.column(col_idx).setWidth(col_width[wb_idx][header[col_idx-1]]);
                    }
                }
            }
    
            var xlsx_b64_buffer = await wb.writeToBuffer();
    
            var xlsx_b64_data = xlsx_b64_buffer.toString('base64');
    
            console.log("Create excel file steam" + " ... Complete Generate Excel File");
    
        }
        catch (e)
        {
            console.log("Create excel file steam"+ " ... Fail Generate Excel File");
            console.log(e);
            return false;
        }
    
    
        return xlsx_b64_data;
    
    },
    async genExcelFile_v2({ commit }, export_config) {
    
        let { data_array, sheet_name } = export_config;

        console.log("sheet_name", sheet_name)

    
        if (!Array.isArray(sheet_name))
        {
            data_array = [data_array];
           
        }

    
        try
        {
            let wb = new XLSX.Workbook();
    
            var style_header = wb.createStyle({
                font: { color: '#000000', size: 12, bold: true },
                fill: { bgColor : '#DDDDDD', type : "none" },
                border: { // §18.8.4 border (Border)
                    left: { style: 'medium', color: '#000000' },
                    right: { style: 'medium', color: '#000000' },
                    top: { style: 'medium', color: '#000000' },
                    bottom: { style: 'medium', color: '#000000' }
                }
            });
            var style_data = wb.createStyle({
                font: { color: '#000000', size: 12, },
                border: { // §18.8.4 border (Border)
                    left: { style: 'thin', color: '#000000' },
                    right: { style: 'thin', color: '#000000' },
                    top: { style: 'thin', color: '#000000' },
                    bottom: { style: 'thin', color: '#000000' }
                }
            });
    
            for (let wb_idx = 0 ; wb_idx < data_array.length ; wb_idx++)
            {
                let ws = wb.addWorksheet(sheet_name[wb_idx]);
                let data_ws = data_array[wb_idx];

                for ( let row_idx = 1 ; row_idx <= data_ws.length ; row_idx++ )
                {
                    for ( let col_idx = 1 ; col_idx <= data_ws[row_idx-1].length ; col_idx++ )
                    {
                        let data_cell = data_ws[row_idx - 1][col_idx - 1];
                        let style = style_data;
                        if (data_cell.style == "header")
                            style = style_header;

                        ws.cell(row_idx, col_idx)
                        .string(data_cell.text.toString())
                        .style( style );
                    }
                    
    
                }
    
                for ( let col_idx = 1 ; col_idx <= data_ws[0].length ; col_idx++ )
                {
                    ws.column(col_idx).setWidth(data_ws[0][col_idx-1].width);
                }
            }
    
            var xlsx_b64_buffer = await wb.writeToBuffer();
    
            var xlsx_b64_data = xlsx_b64_buffer.toString('base64');
    
            console.log("Create excel file steam" + " ... Complete Generate Excel File");
    
        }
        catch (e)
        {
            console.log("Create excel file steam"+ " ... Fail Generate Excel File");
            console.log(e);
            return false;
        }
    
    
        return xlsx_b64_data;
    
    },
    async downloadFromBase64({ commit }, download_config) {
        let { filename, text_b64, type } = download_config;
        // Create an invisible A element
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
      
        // Set the HREF to a Blob representation of the data to be downloaded
        console.log(text_b64);
        a.href = window.URL.createObjectURL(
          new Blob([b64toBlob(text_b64)], { type })
        );
      
        // Use download attribute to set set desired file name
        a.setAttribute("download", filename);
      
        // Trigger the download by simulating click
        a.click();
      
        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }
}

const mutations = {
    SET_LOADING : (state, payload) => {
        state.loading = payload
    },
    SET_SAVING : (state, payload) => {
        state.saving = payload
    },
    SET_LOADING_DATA_TABLE : (state, payload) => {
        state.dataTableLoader = payload
        // console.log('vuex loading',state.dataTableLoader);
    },
    SET_ALERT : (state, payload) => {
        state.alert_state = payload;
    },
    SET_ALERT_MESSAGE : (state, payload) => {
        state.alert_message = payload;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
