import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store';
import { now } from 'moment';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {layout: "fullwidth"},
    component: require('./views/login.vue').default
  },
  { path: '/', redirect: '/Dashboard' },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard.vue'),
    meta: { requiresAuth: true }
    // meta: { guest: true }
  },
  {
    path: '/approve_lead',
    name: 'ApproveLead',
    component: () => import('@/views/approve_lead/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/approve_lead/:id/edit',
    name: 'ApproveLeadEdit',
    component: () => import('@/views/approve_lead/edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/approve_onboard',
    name: 'ApproveOnboard',
    component: () => import('@/views/approve_onboard/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/approve_onboard/:id/edit',
    name: 'ApproveOnboardEdit',
    component: () => import('@/views/approve_onboard/edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import('@/views/drivers/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/drivers/:id/edit',
    name: 'DriversEdit',
    component: () => import('@/views/drivers/edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/drivers/:id/details',
    name: 'DriversDetails',
    component: () => import('@/views/drivers/details.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/category_tag',
    name: 'CategoryTag',
    component: () => import('@/views/categories/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/store_order_report',
    name: 'StoreOrderReport',
    component: () => import('@/views/reports/store_trips.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/store_rider_report',
    name: 'StoreRiderReport',
    component: () => import('@/views/reports/rider_trips.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/trips',
    name: 'TripsReport',
    component: () => import('@/views/reports/trips.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/delivery_time',
    name: 'DeliveryTimeReport',
    component: () => import('@/views/reports/trips_timing.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/transactions',
    name: 'TransactionsReport',
    component: () => import('@/views/reports/transactions.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/tmw',
    name: 'TMWtransactionReport',
    component: () => import('@/views/reports/tmw_transactions.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/incentive/programs',
    name: 'IncentivePrograms',
    component: () => import('@/views/incentive/programs/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/incentive/topup',
    name: 'IncentiveTopup',
    component: () => import('@/views/incentive/topup/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/incentive/topup/:id/details',
    name: 'IncentiveTopupDetails',
    component: () => import('@/views/incentive/topup/details.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/training_schedules',
    name: 'TrainingSchedules',
    component: () => import('@/views/trainings/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/driver_tiers',
    name: 'DriverTiers',
    component: () => import('@/views/driver_tiers/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users_management/users',
    name: 'Users',
    component: () => import('@/views/users/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users_management/user_rolegroups',
    name: 'UsersRolegroups',
    component: () => import('@/views/user_roles/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users_management/user_routegroups',
    name: 'UserRoutegroups',
    component: () => import('@/views/user_routegroups/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users_management/user_permissions',
    name: 'UserPermissions',
    component: () => import('@/views/user_permissions/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users_management/access_log',
    name: 'UserPermissions',
    component: () => import('@/views/log/access_log.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/overview',
    name: 'FulltimeOverview',
    component: () => import('@/views/fulltime/overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/zeek/schedule',
    name: 'ZeekSchedule',
    component: () => import('@/views/zeek/schedule.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/zeek/overview',
    name: 'ZeekOverview',
    component: () => import('@/views/zeek/overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/attendance_review',
    name: 'FulltimeAttendanceReview',
    component: () => import('@/views/fulltime/attendance_review.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/payment_schedule',
    name: 'FulltimePaymentSchedule',
    component: () => import('@/views/fulltime/payment_schedule.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/payment_adjustment',
    name: 'FulltimePaymentAdjustment',
    component: () => import('@/views/fulltime/payment_adjustment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/rostering',
    name: 'FulltimeRostering',
    component: () => import('@/views/fulltime/rostering.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fulltime_rider/report_schedule',
    name: 'TPCRosterReport',
    component: () => import('@/views/fulltime/tpc_roster_report.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manpower/daily',
    name: 'ManPowerDaily',
    component: () => import('@/views/manpower/daily.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instore_driver/import_store_file',
    name: 'Import Store File',
    component: () => import('@/views/instore_driver/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instore_driver/import_history',
    name: 'Import History',
    component: () => import('@/views/instore_driver/history.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/instore_driver/payment',
    name: 'Instore Rider Payment',
    component: () => import('@/views/instore_driver/payment.vue'),
    meta: { requiresAuth: true }
  },
  { path: '/404', 
    name: 'NotFound',
    // meta: {layout: "fullwidth"},                                
    component: require('./views/errors/index.vue').default
  },
  { path: '/405', 
    name: 'Forbidden',
    // meta: {layout: "fullwidth"},                                
    component: require('./views/errors/index.vue').default,
    props: { error_code: 405}
  },
  { path: '*', 
    redirect: '/404'
  }
 
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
]

let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  
  let token = localStorage.getItem('token');
  let exp = localStorage.getItem('token_expire') * 1000;
  let can = JSON.parse(localStorage.getItem('can'));

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token != null && Date.now() < exp) {
        if ( can !== null && !can.find(can => can.name == to.name)) {
          next('/405');
        } else {
          next();
        }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router
