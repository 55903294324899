import axios from 'axios';

const helpers = {
    getToken: () => {
        let token = localStorage.getItem('token');
        let exp = localStorage.getItem('token_expire') * 1000;
        if (token != null && Date.now() < exp) {
            //set default header

            axios.defaults.headers.common["AUTH-TYPE"] = localStorage.getItem('auth_type');
            axios.defaults.headers.common["referrer-path"] = window.location.pathname;


            return token;
        } else {
            return null;
        }
    },
    toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            
            // Read file content on file loaded event
            reader.onload = function(event) {
                resolve(event.target.result);
            };
      
            // Convert data to base64 
            reader.readAsDataURL(file);
        });
    },
}

export default helpers;
