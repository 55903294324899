import axios from 'axios';
import router from '@/routes';
import helpers from '@/helpers'

const state = {
    approve_drivers: [],
    approve_driver_details: {},
    approving_for : "",
    approve_logs : [],
    
}

const getters = {
    allApproveDrivers: (state) => state.approve_drivers,
    ApproveDriverDetails: (state) => state.approve_driver_details,
    approvingFor: (state) => state.approving_for,
    approveLogs: (state) => state.approve_logs,
    
}

const actions = {
    async getApproveDrivers ({ commit, state } ,  options ) {
        // Don't forget to get current route **** to manage type
        options = options || { 
            type: 'onboard',
            page: 1,
            itemsPerPage: 25,
            sortBy: ['updated_at'],
            sortDesc: [true],
            multiSort: true,
            mustSort: true,
            filter: {
              keyword: '',
              approve_step: null,
              training_date: undefined,
              zone: null,
              province: null,
              equipments:[],
              category_tag:[],
              deposit_status: null
            }
        };
        const { type, sortBy, sortDesc, page, itemsPerPage, filter } = options;
        
        var approve_step_id = null;
        if(filter.approve_step){
            approve_step_id = `[${filter.approve_step}]`;
        } else{
            if(state.approving_for == 'lead'){
                approve_step_id = '[1,2]';
            } else {
                approve_step_id = '[3,4,5]';
            }
        }

        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });
        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.keyword ? `&keyword=${filter.keyword}`: ''}${approve_step_id ? `&approve_step_id=${approve_step_id}`: ''}${filter.training_date ? `&training_schedule_id=[${filter.training_date.id}]`: ''}${filter.province ? `&province_id=${filter.province.value}`: ''}${filter.zone ? `&zone=${filter.zone.value}`: ''}${filter.equipments && filter.equipments.length > 0 ? `&equipments=[${filter.equipments}]`: ''}${filter.category_tag && filter.category_tag.length > 0 ? `&category_tag=${JSON.stringify(filter.category_tag)}`: ''}${filter.deposit_status != null ? `&deposit_status=${filter.deposit_status}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            commit('SET_APPROVE_DRIVERS',[]);
            commit('SET_ALLOW_SEARCH', false);
            try{
                let { data } = await axios(config);
                commit('SET_APPROVE_DRIVERS',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_ALLOW_SEARCH', true);

            }
        } else {
            router.push('/login');
        }
        
    },

    async getApproveDriverDetails ({ commit, dispatch }, id) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee/${id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                // console.log(data);
                commit('SET_APPROVE_DRIVER_DETAILS',data);
                commit('SET_APPROVE_LOGS',data.employee_approve_log);
                dispatch('getEvidences',id);
            } catch(err){
                if(err.status == 404){
                    router.push('/404');
                }
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
    
    async approveDriver({ dispatch }, input ){ 
        var AppStepTo = -1;
        if (input.approveType == 'approve'){
            if(state.approving_for == 'lead'){
                AppStepTo = 3;
            } else {
                AppStepTo = 5;
            }
        } else if (input.approveType == 'reject') {
            if(state.approving_for == 'lead'){
                AppStepTo = 2;
            } else {
                AppStepTo = 4;
            }
        }
        if (AppStepTo !== -1){
            var params = { "comment" : input.message };
            const token = helpers.getToken();
            if(token){
                const config = { 
                    method: 'POST',
                    url: `approve/${state.approve_driver_details.id}/${AppStepTo}`,
                    data: JSON.stringify(params),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };
                try {
                    let { data } = await axios(config);
                    router.push('/approve_' + state.approving_for);
                } catch(err) {
                    console.log("Ajax Error",err);
                    if (err.status == 400)
                        alert(err.data.message);
                }
            } else {
                router.push('/login');
            }
        }
    },

    approving ({commit}, step) {
        commit('SET_APPROVING', step);
    },

    async updateApproveDriver ({ commit, dispatch }, driver) {
        const params = {
            'driver-name' : driver.name,
            'driver-email' : driver.email,
            'driver-phone': driver.phone,
            'driver-idcard' : driver.idcard,
            'site-id': driver.selected_site.value,
            'driver-site-name': driver.selected_site.text,
            'training_schedule_id': driver.training_id,
            'equipments' : driver.equipments,
            'deposit_status' : driver.deposit_status,
            'category_tags' : driver.categories,
            'store_uid' : driver.store_uid ? driver.store_uid : null
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: params,
                url: `employee/${driver.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_SAVING',true,{ root: true });
            try{
                let { data } = await axios(config);
                // setTimeout(function() { commit('SET_SAVING',false,{ root: true }); },3000);
                commit('SET_SAVING',false,{ root: true });
                dispatch('setAlert',{ status: true, message: 'Data has been saved successfully.' });
                dispatch('getApproveDriverDetails', driver.id);
                // router.push('/approve_' + state.approving_for);
            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_SAVING',false,{ root: true });
                alert("Error " + err.status + ":  " + err.data.message);
            }
        } else {
            router.push('/login');
        }
    },

    async bulkApprove ({ commit, dispatch, state }, input) {
        commit('SET_LOADING',true,{ root: true });
        var AppStepTo = -1;
        if (input.approveType == 'approve'){
            if(state.approving_for == 'lead'){
                AppStepTo = 3;
            } else {
                AppStepTo = 5;
            }
        } else if (input.approveType == 'reject') {
            if(state.approving_for == 'lead'){
                AppStepTo = 2;
            } else {
                AppStepTo = 4;
            }
        }
        const params = {
            'employee_id' : input.selectedList,
            'comment' : input.comment,
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `bulk_approve/${AppStepTo}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                var options = { 
                    type: state.approving_for,
                    page: 1,
                    itemsPerPage: 25,
                    sortBy: ['updated_at'],
                    sortDesc: [true],
                    multiSort: true,
                    mustSort: true,
                    filter: {
                      keyword: '',
                      approve_step: null,
                      training_date: undefined,
                      zone: null,
                      province: null,
                      equipments:[],
                      category_tag:[],
                      deposit_status: null
                    }
                };
                dispatch('getApproveDrivers', options );
                commit('SET_LOADING',false,{ root: true });
                // dispatch('setLoading', false, { root: true } );
                // router.push('/approve_' + state.approving_for);
                return data;
            } catch(err) {
                commit('SET_LOADING',false,{ root: true });
                alert('Error '+ err.status + '  : ' + err.data.message);
                // console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async bulkAddCategory ({ commit, dispatch}, input) {
        commit('SET_LOADING',true,{ root: true });

        const params = {
                "category_tags" : input.category_tag,
                "employee_id" : input.employee_id
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `employee/bulk/category_tag`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);

                var refreshPage = null;
                if(input.page == 'drivers'){
                    dispatch('getDrivers', { 
                        page: 1,
                        itemsPerPage: 25,
                        sortBy: ['updated_at'],
                        sortDesc: [true],
                        multiSort: true,
                        mustSort: true,
                        filter: {
                          keyword: '',
                          idcard:'',
                          province: null,
                          zone: null,
                          approve_step: null,
                          tier: null,
                          equipments:[],
                          category_tag:[],
                          deposit_status: null,
                          status: null
                        }
                    }, { root: true });
                } else {
                    dispatch('getApproveDrivers', { 
                        type: input.page,
                        page: 1,
                        itemsPerPage: 25,
                        sortBy: ['updated_at'],
                        sortDesc: [true],
                        multiSort: true,
                        mustSort: true,
                        filter: {
                          keyword: '',
                          approve_step: null,
                          training_date: undefined,
                          zone: null,
                          province: null,
                          equipments:[],
                          category_tag:[],
                          deposit_status: null
                        }
                    });
                }

                commit('SET_LOADING',false,{ root: true });
            } catch(err) {
                commit('SET_LOADING',false,{ root: true });
                alert('Error '+ err.status + '  : ' + err.data.message);
                // console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
}

const mutations = {
    SET_APPROVE_DRIVERS (state, payload) {
        state.approve_drivers = payload
    },
    SET_APPROVE_DRIVER_DETAILS (state, payload) {
        state.approve_driver_details = payload
    },
    SET_APPROVING (state, payload){
        state.approving_for = payload
    },
    SET_APPROVE_LOGS (state, payload) {
        let logs = [];
        if(payload.length > 0){
            payload.forEach(function(item){
                logs.push({
                    step_id: item.approve_step_id,
                    step: item.employee_approve_step.name,
                    comment : item.comment,
                    date : item.createdAt
                });
            })
        }
        state.approve_logs = logs;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
};

