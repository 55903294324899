import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';
import moment from 'moment';

const actions = {

    getNoRiderOrdersByTime : async function({ commit } , options) {
        console.log("CALL getNoRiderOrdersByTime")

        let interval = options.interval || 1;
        let span = options.span || 30

        let query_params =  { 
            _page: 1,
            _limit: 5000,
            _sortby: [],
            _order: [],
            promise_st_time: '["' + moment().format("YYYY-MM-DD") + '", "' + moment().format("YYYY-MM-DD") + '"]',
            ln_status: '["ORDERCREATIONNOTIFICATION", "ORDERALLOCATIONSTOP", ""]',
            not_load_tran_zendesk: 1
        }
        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/reports/trips',
                params : query_params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            let { data } = await axios(config);
            console.log(data);

            let order_data = data.data;
            let current_time = data.server_time ? moment(data.server_time) : moment();

            let output_data = {
                "total" : Array(span).fill(0),
                "no_webhook" : Array(span).fill(0),
                "order_creation" : Array(span).fill(0),
                "order_allocation_stop" : Array(span).fill(0),
            }
            let output_order = [];

            for ( let i = 0 ; i < order_data.length ; i++)
            {
                let order = order_data[i];
                let order_time = order.promise_st_time;
                if (order_time == null)
                    order.order_created_at == null ? order.webhook_created_at : order.order_created_at;
                let order_bin = Math.floor((current_time.unix() - moment(order_time).unix()) / (interval * 60))

                if (order_bin >= span)
                    order_bin = span - 1;
                
                output_data["total"][order_bin]++;

                if (order.latest_ln_order_status == "" || order.latest_ln_order_status == null || !order.latest_ln_order_status )
                    output_data["no_webhook"][order_bin]++;
                
                else if (order.latest_ln_order_status == "ORDERALLOCATIONSTOP")
                    output_data["order_allocation_stop"][order_bin]++;

                else if (order.latest_ln_order_status == "ORDERCREATIONNOTIFICATION")
                    output_data["order_creation"][order_bin]++;

                order.store_uid = order.store_uid ? order.store_uid : null;
                //console.log(order.latest_ln_order_status);
                if (current_time.unix() - moment(order_time).unix() >= 0)
                {
                    output_order.push({
                        ...order,
                        //order_no : order.order_no,
                        created_at : order_time,
                        hook_type : order.latest_ln_order_status,
                        diff : current_time.unix() - moment(order_time).unix(),
                        //store_uid : order.store_uid ? order.store_uid : null
                    })
                }
            }

            return {chart_data : output_data, orders : output_order};
        } else {
            router.push('/login');
        }
        
    },

    getAllRedbookStore : async function({ commit } , options) {
        const token = helpers.getToken();
        let params = {};
        if (options && options.load_full && options.load_full === true)
            params = { load_full : true };

        if(token){
            const config = { 
                method: 'GET',
                url: '/store',
                params : params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            let { data } = await axios(config);
            

            return data;
        } else {
            router.push('/login');
        }
    },
    getRedbookBrand : async function({ commit } , options) {
        const token = helpers.getToken();
        let params = {};
        if (options && options.load_full && options.load_full === true)
            params = { load_full : true };

        if(token){
            const config = { 
                method: 'GET',
                url: '/brand',
                params : params,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            let { data } = await axios(config);
            

            return data;
        } else {
            router.push('/login');
        }
    }
}

export default {
    actions,

}
