import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    settings: []
}

const getters = {
    allSettings: (state) => state.settings
}

const actions = {
    async getSettings ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `setting`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                commit('SET_SETTINGS',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createSetting ({ dispatch, rootState, state }, formData) {
        const params = {
            'name': formData.name,
            'value' : formData.value,
            'comment' : formData.comment,
            'rolegroup' : [rootState.Auth.user,rootState.Auth.user.user_role_id]
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `setting`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                dispatch('getSettings');
            })
            .catch(error => {
                switch(error.response.status){
                    case 422:
                    case 423:
                        alert(error.response.data.message);
                        break;
                    default:
                        alert('มีบางอย่างผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async updateSetting ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
            'value' : formData.value,
            'comment' : formData.comment
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `setting/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                dispatch('getSettings');
            })
            .catch(error => {
                if(error.response){
                    switch(error.response.status){
                        case 422:
                        case 423:
                            alert(error.response.data.message);
                            break;
                        default:
                            alert('มีบางอย่างผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
                            break;
                    }
                } else {
                    alert('Connection Error');
                }
            });
        } else {
            router.push('/login');
        }
    },

    async deleteSetting ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `setting/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getSettings');
            } catch(err) {
                console.log("Ajax Error",err);
            }

        } else {
            router.push('/login');
        }
    }

}

const mutations = {
    SET_SETTINGS : (state, payload) => {
        state.settings = payload
      }
}

export default {
    state,
    getters,
    actions,
    mutations
}
