<template>
    <v-dialog v-model="loadingState" max-width="500px" persistent no-click-animation>
        <v-card>
        <v-card-title class="justify-center">
            <span class="headline text-center">{{ header }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <div class="text-center">
                    <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </div>
            </v-container>
        </v-card-text>
        </v-card>
        <!-- End Modal Content -->
    </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'loading_box',
    data: () => ({

    }),
    props:{
        header:{
            type: String,
            default: 'Loading Please wait...'
        }
    },
    methods: {
        ...mapActions(['setLoading']),
    },
    computed: {
        ...mapGetters(['loadingState'])
    },
    watch: {
        dialog (val) {
            val || this.close()
        },
    }
}
</script>