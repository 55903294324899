import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    categories: [],
    categories_array: null
}

const getters = {
    allCategories: (state) => state.categories,
    categoriesArray: (state) => state.categories_array
}

const actions = {
    async getCategories({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `category_tag`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try {
                let { data } = await axios(config);
                commit('SET_CATEGORIES',data);
                var mapOptions = [];
                data.forEach(element => {
                    mapOptions.push(element.name);
                });
                commit('SET_CATEGORIES_ARRAY',mapOptions);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err) {
                // console.log("Ajax Error",err);
                alert("Error " + err.status + ": " + err.message);
            }
        } else {
            router.push('/login');
        }
    },

    async createCategory ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `category_tag`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getCategories');
            } catch(err) {
                // console.log("Ajax Error",err);
                switch(err.status){
                    // case 422:
                    case 423:
                        alert('มีชื่อนี้ในระบบแล้ว');
                        break;
                    default:
                        alert("Error " + err.status + ": " + err.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    },

    async updateCategory ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `category_tag/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getCategories');
            } catch(err) {
                console.log("Ajax Error",err);
                alert("Error " + err.status + ": " + err.message);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteCategory ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `category_tag/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getCategories');
            } catch(err) {
                // console.log("Ajax Error",err);
                switch(err.status){
                    // case 422:
                    // case 423:
                    //     alert('มีชื่อนี้ในระบบแล้ว');
                    //     break;
                    default:
                        alert("Error " + err.status + ": " + err.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    }

}

const mutations = {
    SET_CATEGORIES : (state, payload) => {
        state.categories = payload
      },
    SET_CATEGORIES_ARRAY : (state, payload) => {
        state.categories_array = payload
      }
}

export default {
    state,
    getters,
    actions,
    mutations
}
