<template>
    <div>
        <v-container>
            <v-row align="center" justify="center"
                style="height: 100%;">
                <v-col cols="12" sm="12" class="text-center">
                    <h1>Error {{ error_code }}</h1>
                    <h3 class="grey--text" >{{ error_message }}</h3>
                    <!-- <a @click="$router.go(-1)" style="margin-right:20px;">Back</a>
                    <router-link to="/">Home</router-link> -->
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';


export default {
    name: 'users_edit',
    props:{
      error_code: {
      type: Number,
      default: 404
      },
    },
    components: {
        // pageHeading
    },
    methods: {
        // ...mapActions([''])
    },
    computed: {
        error_message() {
            if(this.error_code == 404){
                return "Sorry, Page not found.";
            } else if(this.error_code == 405){
                return 'Forbidden, access not allowed.'
            } else {
                return 'Ooops, Something went wrong!'
            }
        }
    },
    mounted() {
    }
};
</script>