import axios from 'axios';
import router from '@/routes';
import helpers from '@/helpers'
import moment from 'moment';

const state = {
    user: [],
    user_can: null,
    user_nav: null,
    can_do: null,
}

const getters = {
    userProfile : (state) => state.user,
    userNav : (state) => state.user_nav,
    userCan : (state) => state.user_can,
    canDo : (state) => state.can_do,
}

const actions = {
    
    getTPCTierId({ commit }) {
        return process.env.VUE_APP_TPC_TIER_ID;
    },
    getTPC2TierId({ commit }) {
        return process.env.VUE_APP_TPC_2_TIER_ID;
    },
    getTPCFL1TierId({ commit }) {
        return process.env.VUE_APP_TPC_FL1_TIER_ID;
    },
    getTPCFL2TierId({ commit }) {
        return process.env.VUE_APP_TPC_FL2_TIER_ID;
    },
    genCan({ commit, state }){
        var currentRoute = router.currentRoute;
        if(state.user_can){
            var canDo = state.user_can.find(can => can.name == currentRoute.name);
            // console.log('Route::',canDo.name,canDo.can);
            commit('SET_CANDO', canDo );
        }
    },

    decode({commit},token){
        var base64Url = token.split('.')[1];
        var decodedValue = JSON.parse(window.atob(base64Url));

        // console.log('decode', decodedValue,Date.now());
        localStorage.setItem('token',token);
        localStorage.setItem('token_expire',decodedValue.exp);
        localStorage.setItem('auth_type', 'DM');
        axios.defaults.headers.common["AUTH-TYPE"] = "DM";

        // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },

    decode_redbook({commit},token_set){


        //console.log('token_set', token_set);
        localStorage.setItem('token',token_set.access_token);
        localStorage.setItem('token_expire', moment(token_set.expires).valueOf());
        localStorage.setItem('auth_type', 'REDBOOK');
        axios.defaults.headers.common["AUTH-TYPE"] = "REDBOOK";
        // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },

    async login( {commit, dispatch}, user) {
        //console.log(user);

        if (user.auth_type == "DM")
        {
            const config = { 
                method: 'post',
                url: `auth/login`,
                data: {
                email: user.email,
                password: user.password
                }
            };
            try {
                let { data }  = await axios(config);

                dispatch('decode', data.token );
                dispatch('getUserProfile');
                router.push('/');

            } catch(err) {
                if(err){
                    switch (err.status) {
                        case 403:
                            alert('Invalid username or password');
                        break;
                        default:
                            alert('Oops! Something went wrong.');
                        break;
                    }
                } else {
                    alert('Connection Error!');
                }
            }
        }
        if (user.auth_type == "REDBOOK")
        {

            try {
                let { data }  = await axios.post(process.env.VUE_APP_SS_API_URL + "/api-store/auth", {
                    username: user.email,
                    password: user.password
                });

                dispatch('decode_redbook', data );
                dispatch('getUserProfile');
                router.push('/');

            } catch(err) {
                if(err){
                    switch (err.status) {
                        case 503:
                            alert('Invalid username or password');
                        break;
                        default:
                            alert('Oops! Something went wrong.');
                        break;
                    }
                } else {
                    alert('Connection Error!');
                }
            }
        }
    },

    logout({ commit , dispatch }) {
        dispatch('clearToken');
        commit('UPDATE_USER', []);
        router.push('/login');
    },

    can({ getters }) {
        return true;
    },

    clearToken({ commit }) {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expire');
        localStorage.removeItem('auth_type');
        localStorage.removeItem('can');
    },

    async getUserProfile({ commit , state, dispatch }, force) {
        force = force || false;
        // When restart app, if no user profile then call ajax
        if(state.user.length == 0 || force){  
            // check if has token and not expire, otherwise send to login page
            const token = helpers.getToken();
            if(token){
                const config = { 
                    method: 'GET',
                    url: `user/me`,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };
                try {
                    let { data } = await axios(config);
                    commit('UPDATE_USER', data);
                    commit('SET_USER_CAN', data.permission.route);
                    commit('SET_USER_NAV', data.permission.nav?.filter?.(
                        (route) => route?.name != "Instore Driver"
                      ));

                    dispatch('genCan');

                } catch(err) {
                    console.log("Ajax Error",err);
                }
            } else {
                router.push('/login');
            }
        }
    },

    async updateUserProfile ({ dispatch }, formData) {
        const params = {
            // "email": formData.email,
            // "password": formData.password,
            "fullname": formData.fullname,
            "user_role_id" : formData.user_role_id
        };
        if(formData.password && formData.password  !== '') {
            params.password = formData.password 
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `user/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserProfile',true);

            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
}

const mutations = {
    UPDATE_USER : (state, payload) => {
        state.user = payload
    },
    SET_USER_CAN : (state, payload) => {
        state.user_can = payload
        localStorage.setItem('can',JSON.stringify(state.user_can));
    },
    SET_CANDO : (state, payload) => {
        state.can_do = payload
    },
    SET_USER_NAV : (state, payload) => {
        state.user_nav = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
