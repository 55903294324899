import axios from 'axios';
import helpers from '@/helpers';
import _ from 'lodash';
import moment from 'moment';
import router from '@/routes';

const state = {
    trainings: [],
    present_trainings: [],
    using_trainings: [],
    trainings_options: [],
}

const getters = {
    allTrainings: (state) => state.trainings,
    usingTrainings: (state) => state.using_trainings,
    trainingOptions: (state) => {
        var options = [];
        for( let [key,val] of Object.entries(state.trainings) ){
            options.push({
                'id' : val.id,
                'value' : moment(val.training_date).format('DD-MM-YYYY') 
                        + ' (' 
                        + moment(val.training_time_from,"HH:mm:ss").format('HH:mm') 
                        + '-' 
                        + moment(val.training_time_to,"HH:mm:ss").format('HH:mm') + ')'
                        + (val.store ? " " + val.store.store_alias_id + " " + val.store.name_th : "")
            });
        }
        return options;
    }
}

const actions = {
    async getTrainings ({ commit } , query) {
        const token = helpers.getToken();
        var urlQry = 'training_schedule';
        if(query != 'all'){
            urlQry = urlQry + '?' + query + '=1'
        }
        if(token){
            const config = { 
                method: 'GET',
                url: urlQry,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try {
                let { data } = await axios(config);
                commit('SET_TRAININGS',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch (err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async getUsingTrainings ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `training_schedule?filter_empty=1`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                commit('SET_USING_TRAININGS',data);
            } catch (err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createTraining ({ dispatch }, formData) {
        const params = {
            "training_date": formData.training_date,
            "training_time_from": formData.training_time_from,
            "training_time_to": formData.training_time_to,
            "training_location": formData.training_location,
            "training_seat" : parseInt(formData.training_seat),
        };

        if (formData.employee_tier_id)
        {
            params.employee_tier_id = formData.employee_tier_id;
        }
        else
        {
            params.employee_tier_id = null;
        }

        if (formData.store_uid)
        {
            params.store_uid = formData.store_uid;
        }
        else
        {
            params.store_uid = null;
        }

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `training_schedule`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                dispatch('getTrainings','all');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateTraining ({ dispatch }, formData) {
        const params = {
            "training_date": formData.training_date,
            "training_time_from": formData.training_time_from,
            "training_time_to": formData.training_time_to,
            "training_location": formData.training_location,
            "training_seat" : parseInt(formData.training_seat)
        };

        if (formData.employee_tier_id)
        {
            params.employee_tier_id = formData.employee_tier_id;
        }
        else
        {
            params.employee_tier_id = null;
        }

        if (formData.store_uid)
        {
            params.store_uid = formData.store_uid;
        }
        else
        {
            params.store_uid = null;
        }

        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `training_schedule/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                console.log(config);
                let { data } = await axios(config);
                console.log(data);
                dispatch('getTrainings','all');
            } catch(err) {
                console.log("Ajax Error",err);
                switch(err.status){
                    // case 422:
                    // case 423:
                    //     alert(err.response.data.message);
                    //     break;
                    default:
                        alert('Error '+ err.status + '  : ' + err.data.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    },

    async deleteTraining ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `training_schedule/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                dispatch('getTrainings','all');
            } catch(err) {
                if(err == 'Error: Request failed with status code 412') {
                    alert('Deleting this training schedule is not allowed');
                } else {
                    console.log("Ajax Error",err);
                }
            }
        } else {
            router.push('/login');
        }
    },


    getEmployeeTier : async function({ commit } , options) {
        const token = helpers.getToken();
        
        if(token){
            const config = { 
                method: 'GET',
                url: '/employee_tier',
                params : {},
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            let { data } = await axios(config);
            

            return data;
        } else {
            router.push('/login');
        }
    }
}

const mutations = {
    SET_TRAININGS : (state, payload) => {
        state.trainings = payload
      },
    SET_USING_TRAININGS : (state, payload) => {
        state.using_trainings = payload
      },

}

export default {
    state,
    getters,
    actions,
    mutations
}
