import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';
import moment from 'moment'

const actions = {
    
    getPaymentScheme : async function({ commit }) {
        console.log("CALL getPaymentScheme")

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_scheme',
                data : { },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    addTPCRosterReportSchedule : async function({ commit } , newItem) {
        console.log("CALL addTPCRosterReportSchedule")
        console.log(newItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/tpc_roster_report',
                data : {
                    start_date : newItem.start_date,
                    end_date : newItem.end_date,
                    execute_date : newItem.execute_date,
                    humanica_to_emails : newItem.humanica_email_to,
                    humanica_cc_emails : newItem.humanica_email_cc,
                    ...(newItem.template ? {template : newItem.template} : {} )
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true, data : data };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    updateTPCRosterReportSchedule : async function({ commit } , updateItem) {
        console.log("CALL updateTPCRosterReportSchedule")
        console.log(updateItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                url: '/tpc_roster_report/' + updateItem.id,
                data : {
                    start_date : updateItem.start_date,
                    end_date : updateItem.end_date,
                    execute_date : updateItem.execute_date,
                    humanica_to_emails : updateItem.humanica_email_to,
                    humanica_cc_emails : updateItem.humanica_email_cc
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true, data : data };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    addPaymentSchedule : async function({ commit } , newItem) {
        console.log("CALL addPaymentSchedule")
        console.log(newItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/fulltime_payment_schedule',
                data : {
                    name : newItem.name,
                    fulltime_payment_scheme_id : newItem.fulltime_payment_scheme_id,
                    period_start_date : newItem.start_date,
                    period_end_date : newItem.end_date,
                    paid_date : newItem.paid_date
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true, data : data };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getTPCRosterReportSchedule : async function({ commit }) {
        console.log("CALL getTPCRosterReportSchedule")

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/tpc_roster_report',
                data : { },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    getPaymentSchedule : async function({ commit }) {
        console.log("CALL getPaymentSchedule")

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_schedule',
                data : { },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    getNonPaidPaymentSchedule : async function({ commit }) {
        console.log("CALL getPaymentSchedule")

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_schedule',
                params : { is_processed : false },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    getPaymentScheduleDetail : async function({ commit }, schedule) {
        console.log("CALL getPaymentScheduleDetail")

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_schedule/' + schedule.id,
                data : { },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    deleteTPCRosterReport : async function({ commit } , deleteItem) {
        console.log("CALL deletePaymentSchedule")
        console.log(deleteItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: '/tpc_roster_report/' + deleteItem.id,
                data : {
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },


    executeTPCRosterReport : async function({ commit } , executeItem) {
        console.log("CALL executeTPCRosterReport")
        console.log(executeItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/tpc_roster_report/' + executeItem.id + "/dispatch",
                data : {
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    deletePaymentSchedule : async function({ commit } , deleteItem) {
        console.log("CALL deletePaymentSchedule")
        console.log(deleteItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: '/fulltime_payment_schedule/' + deleteItem.id,
                data : {
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    paidPaymentSchedule : async function({ commit } , paidItem) {
        console.log("CALL paidPaymentSchedule")
        console.log(paidItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/payroll/pay_fulltime',
                data : {
                    schedule_id : paidItem.id
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    updatePaymentSchedule : async function({ commit } , updateItem) {
        console.log("CALL updatePaymentSchedule")
        console.log(updateItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                url: '/fulltime_payment_schedule/' + updateItem.id,
                data : {
                    period_start_date : updateItem.start_date,
                    period_end_date : updateItem.end_date,
                    paid_date : updateItem.paid_date
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    addPaymentAdjustment : async function({ commit } , newItem) {
        console.log("CALL addPaymentAdjustment")
        console.log(newItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/fulltime_payment_adjustment',
                data : {
                    employee_no : newItem.employee_no,
                    appiled_at : newItem.appiled_at,
                    type : newItem.type,
                    amount : newItem.amount,
                    description : newItem.description,
                    schedule_id : newItem.schedule_id
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true, data : data };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getPaymentAdjustment : async function({ commit }, search) {
        console.log("CALL getPaymentAdjustment")
        let local_search = {}
        if (search.employee_keyword)
            local_search.employee_keyword = search.employee_keyword;
        if (search.appiled_at)
        {
            if (search.appiled_at.length == 1)
                local_search.appiled_at = search.appiled_at[0];
            else if (search.appiled_at.length == 2)
            {
                local_search.appiled_at_from = search.appiled_at[0];
                local_search.appiled_at_to = search.appiled_at[1];
            }
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_adjustment',
                params : local_search,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    deletePaymentAdjustment : async function({ commit } , deleteItem) {
        console.log("CALL deletePaymentAdjustment")
        console.log(deleteItem)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: '/fulltime_payment_adjustment/' + deleteItem.id,
                data : {
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },
    ImportPaymentAdjustment: async function({ commit } , importItem) {
        console.log("CALL ImportPaymentAdjustment")
        console.log(importItem);

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/fulltime_payment_adjustment/import',
                data : {
                    import_data : importItem.import_content,
                    schedule_id : importItem.schedule_id,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { isComplete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },
    deleteAllPaymentAdjustment : async function({ commit } , scheduleId) {
        console.log("CALL deleteAllPaymentAdjustment")
        console.log(scheduleId)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: '/fulltime_payment_adjustment/schedule/' + scheduleId,
                data : {},
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },
    checkImportPaymentAdjustmentFile: async function({ commit } , importItem) {
        console.log("checkImportPaymentAdjustmentFile");
        const XLSX = require('xlsx');
        if (importItem.importFile)
        {
            try
            {
                let xlsx_b64 = await helpers.toBase64(importItem.importFile);
                let file = XLSX.read(xlsx_b64.substring(xlsx_b64.indexOf(",")+1));
                let sheet_name = file.SheetNames[0];
                let worksheet = file.Sheets[sheet_name];
                let data = XLSX.utils.sheet_to_json(worksheet);
                console.log(data);
                if (data.length == 0)
                {
                    return {
                        is_complete : false,
                        message : "No data in Excel file"
                    }
                }
                else
                {
                    for (let i = 0 ; i < data.length ; i++)
                    {
                        let chk_data = data[i];
                        if (!chk_data["Slot Id"] && !chk_data["Zeek Shift Uid"])
                        {
                            return {
                                is_complete : false,
                                import_content : [],
                                message : "No Slot Id and Zeek Shift Uid column"
                            }
                        }
                        if (!Number.isFinite(parseFloat(chk_data["Driver Earn"])))
                        {
                            console.log(chk_data);
                            return {
                                is_complete : false,
                                import_content : [],
                                message : "No Driver Earn column"
                            }
                        }
                        if (!Number.isFinite(parseFloat(chk_data["Actual Earn"])))
                        {
                            return {
                                is_complete : false,
                                import_content : [],
                                message : "No Actual Earn column or it contain blank record."
                            }
                        }
                    }

                    return {
                        is_complete : true,
                        import_content : data,
                        message : ""
                    }

                }
            }
            catch(e)
            {
                console.log(e);
                return {
                    is_complete : false,
                    message : "The uploaded file can not be processed."
                }
            }
        }
        else
        {
            return {
                is_complete : false,
                message : "No file"
            }
        }
    },
    checkRosteringFile: async function({ commit } , importItem) {
        console.log("checkRosteringFile");

        const XLSX = require('xlsx');
        if (importItem.importFile)
        {
            try
            {
                let xlsx_b64 = await helpers.toBase64(importItem.importFile);
                let file = XLSX.read(xlsx_b64.substring(xlsx_b64.indexOf(",")+1));
                let sheet_name = file.SheetNames[0];
                let worksheet = file.Sheets[sheet_name];
                let data = XLSX.utils.sheet_to_json(worksheet);

                if (data.length == 0)
                {
                    return {
                        isImportFileValid : false,
                        importFileContent : [],
                        importFileErrorLog : "No data in Excel file"
                    }
                }
                else
                {
                    for (let i = 0 ; i < data.length ; i++)
                    {
                        let chk_data = data[i];
                        if (!chk_data["Store Code"])
                        {
                            return {
                                isImportFileValid : false,
                                importFileContent : [],
                                importFileErrorLog : "No Store Code column"
                            }
                        }
                        if (!chk_data["รหัสคนขับ"])
                        {
                            return {
                                isImportFileValid : false,
                                importFileContent : [],
                                importFileErrorLog : "No รหัสคนขับ column"
                            }
                        }
                        if (!chk_data["Check-In"])
                        {
                            return {
                                isImportFileValid : false,
                                importFileContent : [],
                                importFileErrorLog : "No Check-In column"
                            }
                        }
                        if (!chk_data["Check-Out"])
                        {
                            return {
                                isImportFileValid : false,
                                importFileContent : [],
                                importFileErrorLog : "No Check-Out column"
                            }
                        }
                    }

                    return {
                        isImportFileValid : true,
                        importFileContent : data,
                        importFileErrorLog : "The system found " + data.length + " records in the imported file"
                    }

                }
            }
            catch(e)
            {
                console.log(e);
                return {
                    isImportFileValid : false,
                    importFileContent : [],
                    importFileErrorLog : "The import file can not be processed."
                }
            }
        }
        else
        {
            return {
                isImportFileValid : null,
                importFileContent : [],
                importFileErrorLog : ""
            }
        }
    },


    importRostering: async function({ commit } , {importItem, schedule_id}) {

        console.log("CALL importRostering")
        console.log(importItem);
        console.log("schedule_id", schedule_id);

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/rostering_slot/import',
                data : {
                    data : importItem.importFileContent,
                    schedule_id : schedule_id,
                    ignoreAllWhenError : importItem.ignoreAllWhenError,
                    modificationMethod : importItem.modificationMethod,
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { isComplete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },
    exportPayment : async function({ commit }, schedule_id) {
        console.log("CALL exportPayment", schedule_id)

        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_schedule/' + schedule_id + '/payment_report',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return true;
            }
            catch(e)
            {
                console.log(e);
                return false;
            }
        } else {
            router.push('/login');
        }

    },
    exportFullPayment : async function({ commit }, schedule_id) {
        console.log("CALL exportFullPayment", schedule_id)

        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/fulltime_payment_schedule/' + schedule_id + '/full_payment_report',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return true;
            }
            catch(e)
            {
                console.log(e);
                return false;
            }
        } else {
            router.push('/login');
        }

    },
    getRostering : async function({ commit }, schedule_id) {
        console.log("CALL getRostering", schedule_id)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/rostering_slot',
                params : { schedule_id : schedule_id },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);

                //transform data
                let output_data = {};
                for(let i = 0 ; i < data.length ; i++)
                {
                    let output_key = data[i].employee_id + "|" + data[i].store_uid + "|" + data[i].slot_start + "|" + data[i].slot_end;
                    if (!output_data[output_key])
                    {
                        output_data[output_key] = { ...data[i] };
                        for (var m = moment(data[i].fulltime_payment_schedule.period_start_date); m.isSameOrBefore(data[i].fulltime_payment_schedule.period_end_date); m.add(1, 'days')) {
                            output_data[output_key][m.format("YYYY-MM-DD")] = { planing : "N/A", id : null};
                        }
                        output_data[output_key][data[i].slot_date] = { planing : "Y",  id : data[i].id} ;
                        delete output_data[output_key].slot_date;
                    }
                    else
                    {
                        output_data[output_key][data[i].slot_date] = { planing : "Y",  id : data[i].id};
                    }
                }

                console.log(output_data);
                return Object.values(output_data);
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    getOverallRostering : async function({ commit }) {

        console.log("CALL getOverallRostering");

        const lodash = require('lodash');
        const moment = require('moment-timezone');

        let current_date = moment().tz("Asia/Bangkok");
        let start_date = moment().tz("Asia/Bangkok").subtract(1, "days");
        let end_date = moment().tz("Asia/Bangkok").add(4, "days");

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/rostering_slot/timesheet',
                params : { slot_date_between : JSON.stringify([start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD")]) },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);

                //transform data
                let output_data = {};
                for(let i = 0 ; i < data.length ; i++)
                {
                    let output_key = data[i].employee_id + "|" + data[i].store_uid + "|" + data[i].slot_start + "|" + data[i].slot_end;
                    if (!output_data[output_key])
                    {
                        output_data[output_key] = { ...data[i] };
                        for (var m = start_date.clone(); m.isSameOrBefore(end_date); m.add(1, 'days')) {
                            output_data[output_key][m.format("YYYY-MM-DD")] = { planing : "N/A", id : null};
                        }
                    }
                    let slot_start = moment(data[i].slot_date + " " + data[i].slot_start + "+0700", 'YYYY-MM-DD HH:mm:ssZ').subtract(30, "minutes");

                    if (slot_start.isAfter(current_date) )
                        output_data[output_key][data[i].slot_date] = { planing : "PLAN",  id : data[i].id} ;
                    else
                    {
                        
                        if (current_date.isBefore(slot_start))
                        {
                            console.log("aaaa");
                            output_data[output_key][data[i].slot_date] = { planing : "PLAN",  id : data[i].id} ;
                        }
                        else
                        {
                            if (data[i].rostering_slot_timesheet == null)
                            {
                                output_data[output_key][data[i].slot_date] = { planing : "OFF",  id : data[i].id} ;
                            }
                            else if (data[i].rostering_slot_timesheet.end_status_id != null)
                            {
                                if (data[i].rostering_slot_timesheet.end_status_id == 2)
                                    output_data[output_key][data[i].slot_date] = { planing : "OUT",  id : data[i].id} ;
                                else if (data[i].rostering_slot_timesheet.end_status_id == 5)
                                    output_data[output_key][data[i].slot_date] = { planing : "OUT LATE",  id : data[i].id} ;
                                else if (data[i].rostering_slot_timesheet.end_status_id == 3)
                                    output_data[output_key][data[i].slot_date] = { planing : "AUTO",  id : data[i].id} ;
                            }
                            else
                            {
                                if (data[i].rostering_slot_timesheet.start_status_id == 1)
                                    output_data[output_key][data[i].slot_date] = { planing : "IN",  id : data[i].id} ;
                                    else if (data[i].rostering_slot_timesheet.start_status_id == 4)
                                    output_data[output_key][data[i].slot_date] = { planing : "IN LATE",  id : data[i].id} ;
                                    else   
                                    output_data[output_key][data[i].slot_date] = { planing : "OFF",  id : data[i].id} ;

                            }
                        }
                    }


                    output_data[output_key][data[i].slot_date].rostering_slot_timesheet = data[i].rostering_slot_timesheet;
                    delete output_data[output_key].slot_date;
                    delete output_data[output_key].rostering_slot_timesheet;
                }

                console.log(output_data);
                return Object.values(output_data);
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    getOverallRosteringByStoreSchedule : async function({ commit }) {

        console.log("CALL getOverallRostering");

        const lodash = require('lodash');
        const moment = require('moment-timezone');

        let current_date = moment().tz("Asia/Bangkok");
        let start_date = moment().tz("Asia/Bangkok").subtract(1, "days");
        let end_date = moment().tz("Asia/Bangkok").add(4, "days");

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/rostering_slot/timesheet',
                params : { slot_date_between : JSON.stringify([start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD")]) },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);

                //transform data
                let output_data = {};
                for(let i = 0 ; i < data.length ; i++)
                {
                    let output_key = data[i].store_uid;
                    if (!output_data[output_key])
                    {
                        output_data[output_key] = {
                            store_name : data[i].store_name,
                            schedules : {}
                        };
                    }

                    if (!output_data[output_key].schedules[data[i].fulltime_payment_schedule.id])
                    {
                        output_data[output_key].schedules[data[i].fulltime_payment_schedule.id] = {
                            name : data[i].fulltime_payment_schedule.name,
                            slots : {}
                        }

                        for (var m = start_date.clone(); m.isSameOrBefore(end_date); m.add(1, 'days'))
                        {
                            output_data[output_key].schedules[data[i].fulltime_payment_schedule.id].slots[m.format("YYYY-MM-DD")] = {
                                planing : 0,
                                online : 0,
                                offline : 0,
                                data : []
                            }
                        }

                    }

                    let roster_status = null;

                    let slot_start = moment(data[i].slot_date + " " + data[i].slot_start + "+0700", 'YYYY-MM-DD HH:mm:ssZ').subtract(30, "minutes");

                    if (slot_start.isAfter(current_date) )
                        roster_status =  "PLAN";
                    else
                    {
                        
                        if (current_date.isBefore(slot_start))
                        {
                            roster_status = "PLAN";
                        }
                        else
                        {
                            if (data[i].rostering_slot_timesheet == null)
                            {
                                roster_status = "OFF";
                            }
                            else if (data[i].rostering_slot_timesheet.end_status_id != null)
                            {
                                if (data[i].rostering_slot_timesheet.end_status_id == 2)
                                    roster_status = "OUT";
                                else if (data[i].rostering_slot_timesheet.end_status_id == 5)
                                    roster_status = "OUT LATE";
                                else if (data[i].rostering_slot_timesheet.end_status_id == 3)
                                    roster_status = "AUTO";
                            }
                            else
                            {
                                if (data[i].rostering_slot_timesheet.start_status_id == 1)
                                    roster_status =  "IN";
                                else if (data[i].rostering_slot_timesheet.start_status_id == 4)
                                    roster_status = "IN LATE";
                                else   
                                    roster_status = "OFF";
                            }
                        }

                    }
                    if (roster_status != null)
                    {
                        output_data[output_key].schedules[data[i].fulltime_payment_schedule.id].slots[data[i].slot_date].planing++;
                        if (roster_status == "OFF")
                            output_data[output_key].schedules[data[i].fulltime_payment_schedule.id].slots[data[i].slot_date].offline++;
                        else if (roster_status != "PLAN")
                            output_data[output_key].schedules[data[i].fulltime_payment_schedule.id].slots[data[i].slot_date].online++;
                        
                    }
                    data[i].roster_status = roster_status;
                    output_data[output_key].schedules[data[i].fulltime_payment_schedule.id].slots[data[i].slot_date].data.push(data[i]);
                }

                console.log(output_data);
                return Object.values(output_data);
            }
            catch(e)
            {
                console.log(e);
                return [];
            }
        } else {
            router.push('/login');
        }
    },

    deleteRostering : async function({ commit } , rostering_slot_id) {
        console.log("CALL deleteRostering")
        console.log("rostering_slot_id", rostering_slot_id)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: '/rostering_slot/' + rostering_slot_id,
                data : {
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true };
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getZeekSchedule : async function({ commit } , op) {
        console.log("CALL getZeekSchedule")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/zeek/roster/schedule',
                params : 
                    {...op}
                ,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getZeekOverview : async function({ commit } , op) {
        console.log("CALL getZeekOverview")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/zeek/roster/shift_overview',
                params : 
                    {...op}
                ,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getZeekShift : async function({ commit } , op) {
        console.log("CALL getZeekSchedule")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/zeek/roster/schedule/' + op.zeek_schedule_id + '/shift',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getAvailableZeekRoster : async function({ commit } , op) {
        console.log("CALL getZeekSchedule")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: '/zeek/roster/payment/' + op.fulltime_payment_id + '/available',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    addZeekRosterPayment : async function({ commit } , op) {
        console.log("CALL addZeekRosterPayment")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                url: '/zeek/roster/payment/' + op.fulltime_payment_id + '/add',
                data: op.payload,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    deleteZeekRosterPayment : async function({ commit } , op) {
        console.log("CALL deleteZeekRosterPayment")
        console.log("op", op)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                url: '/zeek/roster/payment/' + op.fulltime_payment_id + '/delete',
                data: op.payload,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try
            {
                let { data } = await axios(config);
                return data;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, message : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },
}

export default {
    actions,

}
