import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';
import moment from 'moment'

const state = {
    instorecomplete: [],
}

const getters = {
    instoreImportComplete : (state) => state.instorecomplete
}
const actions = {
    
    checkImportInstoreFile: async function({ commit , dispatch } , importItem) {
        console.log("checkImportInstoreFile");
        const XLSX = require('xlsx');
        if (importItem.importFile)
        {
            try
            {
                let xlsx_b64 = await helpers.toBase64(importItem.importFile);
                let file = XLSX.read(xlsx_b64.substring(xlsx_b64.indexOf(",")+1));
                let sheet_name = file.SheetNames[0];
                let worksheet = file.Sheets[sheet_name];
                let data = XLSX.utils.sheet_to_json(worksheet);

                const header = []
                const columnCount = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1
                for (let i = 0; i < columnCount; ++i) {
                    if(worksheet[`${XLSX.utils.encode_col(i)}1`]){
                        header[i] = worksheet[`${XLSX.utils.encode_col(i)}1`].v
                    }
                }

                if (data.length == 0)
                {
                    return {
                        is_complete : false,
                        message : "No data in Excel file"
                    }
                }
                else
                {
                    let normalize_data = {};
                    normalize_data.data = [];
                    normalize_data.filename = importItem.importFile.name;

                    if(!header.includes('Store Name')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No Store Name column"
                        }
                    }
                    if(!header.includes('Order Time')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No Order Time column"
                        }
                    }
                    if(!header.includes('Phone Customer')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No Phone Customer column"
                        }
                    }
                    if(!header.includes('ID')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No ID column"
                        }
                    }
                    if(!header.includes('Phone Driver')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No Phone Driver column"
                        }
                    }
                    if(!header.includes('Date')){
                        return {
                            is_complete : false,
                            import_content : [],
                            message : "No Date column"
                        }
                    }

                    for (let i = 0 ; i < data.length ; i++)
                    {
                        let nm_data = {}
                        for(let k in data[i]){
                            nm_data[k.toLowerCase()] = data[i][k]
                        }

                        normalize_data.data.push(nm_data)
                    }


                    const token = helpers.getToken();
                    if(token){
                        const config = { 
                                method: 'POST',
                                url: '/instore_import/upload',
                                data : JSON.stringify(normalize_data),
                                headers: {
                                    "Authorization": `Bearer ${token}`,
                                    "Content-Type": "application/json;charset=UTF-8",
                                }
                            };

                        try
                        {
                            let { data } = await axios(config);
                            console.log("===upload===" + JSON.stringify(data));
                            if(data.is_error == true){
                                return { is_complete : false , import_content : null};
                            }else{
                                const config_order = { 
                                    method: 'GET',
                                    url: '/instore_import/orders/' + data.task_id,
                                    headers: {
                                        "Authorization": `Bearer ${token}`,
                                        "Content-Type": "application/json;charset=UTF-8",
                                    }
                                };
                
                                try
                                {
                                    let data_order = await axios(config_order);
                                    // console.log("===get-order===" + JSON.stringify(data_order.data));
                                    return { is_complete : true , task_id : data.task_id, import_content : data_order.data.rows , message : ''};
                                }
                                catch(e)
                                {
                                    console.log(e);
                                    return { is_complete : false, errorMessage : e.data.message };
                                }
                            }
                        }
                        catch(e)
                        {
                            console.log(e);
                            return { is_complete : false, errorMessage : e.data.message };
                        }
                    } else {
                        router.push('/login');
                    }
                }
            }
            catch(e)
            {
                console.log(e);
                return {
                    is_complete : false,
                    message : "The uploaded file can not be processed."
                }
            }
        }
        else
        {
            return {
                is_complete : false,
                message : "No file"
            }
        }
    },

    getInstoreImportOrder: async function({ commit, dispatch }, id){
        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/orders/' + id,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true , task_id : id, import_content : data.rows};
            }
            catch(e)
            {
                console.log(e);
                return { isComplete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getInstoreImportOrderHistory: async function({ commit, dispatch }, id){
        const token = helpers.getToken();

        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/orders/' + id + '?record_case=1,2,3,9,10,11',
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true , task_id : id, import_content : data.rows};
            }
            catch(e)
            {
                console.log(e);
                return { isComplete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getInstoreImportOrderPayment: async function({ commit, dispatch }, id){
        const token = helpers.getToken();

        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/orders/' + id + '?record_case=1,2,3,10',
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return { is_complete : true , task_id : id, import_content : data.rows};
            }
            catch(e)
            {
                console.log(e);
                return { isComplete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    checkImportTaskStatus: async function({ commit, dispatch }, id){
        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/tasks?task_id=' + id,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return  data.rows ? data.rows[0].task_status : null ;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    getSMSTemplateTask: async function({ commit, dispatch }, id){
        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/tasks?task_id=' + id,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return  data.rows ? JSON.parse(data.rows[0].sms_template) : null ;
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        } else {
            router.push('/login');
        }
    },

    async getImportInstoreComplete ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `instore_import/tasks?status=IMPORT_COMPLETE`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                commit('SET_IMPORTS',data.rows);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    confirmInstoreImport: async function({ commit, dispatch }, id){
        const token = helpers.getToken();
        let payload = {'sms_template' : process.env.VUE_APP_SMSTEMPLATE_INSTORE}
        if(token){
            const config = { 
                    method: 'POST',
                    url: '/instore_import/confirmed_upload/' + id,
                    data : payload,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    },

    confirmInstorePayment: async function({ commit, dispatch }, payment_data){
        const token = helpers.getToken();

        let payment_post = {
            'paid_now' : payment_data.paid_order_no,
            'require_approve' : payment_data.approve_order_no,
        }

        if(token){
            const config = { 
                    method: 'POST',
                    url: '/instore_import/pay_order/' + payment_data.task_id,
                    data : JSON.stringify(payment_post),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    },
                    
    getAllOrderlistInstore : async function({commit, dispatch}){
        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/order_list/',
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    },

    getAllRiderPhonelistInstore :  async function({commit, dispatch}){
        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'GET',
                    url: '/instore_import/employees',
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    },

    updateOrderInstore : async function({commit, dispatch},data_update){
        let payload = {
            'order_no' : data_update.selected_order,
            'rider_phone' : data_update.rider_phone,
        };

        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'POST',
                    url: '/instore_import/edit_order/' + data_update.order_id,
                    data : JSON.stringify(payload),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    },

    updateSMSTemplate : async function ({commit, dispatch},data_update){
        let payload = {
            'sms_template' : JSON.stringify(data_update.sms_template),
        };

        const token = helpers.getToken();
        if(token){
            const config = { 
                    method: 'POST',
                    url: '/instore_import/tasks/' + data_update.task_id + '/sms-template',
                    data : payload,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json;charset=UTF-8",
                    }
                };

            try
            {
                let { data } = await axios(config);
                return data
            }
            catch(e)
            {
                console.log(e);
                return { is_complete : false, errorMessage : e.data.message };
            }
        }
    }
    

}

const mutations = {
    SET_IMPORTS : (state, payload) => {
        state.instorecomplete = payload
    },
}

export default {
    actions,
    mutations,
    state,
    getters
}
