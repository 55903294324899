import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    incentives: [],
    incentive_topups: [],
    incentive_topups_detail: []
}

const getters = {
    allIncentives: (state) => state.incentives,
    allIncentiveTopups: (state) => state.incentive_topups,
    IncentiveTopupsDetail: (state) => state.incentive_topups_detail
}

const actions = {
    async getIncentives ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `incentives/programs`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                commit('SET_INCENTIVES',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createIncentive ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
            is_deductable : formData.is_deductable ? formData.is_deductable : false,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: params,
                url: `incentives/programs`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getIncentives');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateIncentive ({ dispatch }, formData) {
        const params = {
            'name': formData.name,
            is_deductable : formData.is_deductable,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: params,
                url: `/incentives/programs/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getIncentives');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteIncentive ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `/incentives/programs/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getIncentives');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async getIncentiveTopups ({ commit } , options ) {
        options = options || { 
                                    sortBy: ['updatedAt'],
                                    sortDesc: [true],
                                    filter: {
                                        program: '',
                                        date: '',
                                        status: '',
                                    }
                                };
        const { sortBy, sortDesc, page, itemsPerPage, filter } = options;
        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `/incentives/topups?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]&program=${
                        filter.program || ''}&date=${filter.date || ''}&status=${filter.status || ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true })
            try{
                
                let { data } = await axios(config);
                commit('SET_INCENTIVE_TOPUPS',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
                
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
        
    },

    async createIncentiveTopups ({ dispatch, commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        const base64_result = await helpers.toBase64(formData.topup_file).catch(e => Error(e));
        if(base64_result instanceof Error) {
            alert('Error: ', base64_result.message);
            return;
        } 
        // else {
        //     console.log('Base64',base64_result.split(",")[1]);
        // }

        //** Prepare to post image */
        const params = {
            'program': formData.incentive_program_id,
            'date': formData.incentive_date,
            'file': base64_result.split(",")[1],
            'replace': formData.replace,
            'sms_template' : formData.sms_template,
            'wallet_code' : formData.wallet_code
        };

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/incentives/topups`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                if (router.currentRoute.name == 'IncentiveTopup') {
                    dispatch('getIncentiveTopups');
                } else {
                    dispatch('getIncentiveTopupsDetail',router.currentRoute.params.id);
                }
                commit('SET_LOADING',false,{ root: true });
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    case 423:
                        alert("Incentive topups program on your selected date is existing.");
                        break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async updateIncentiveSMS ({ dispatch, commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        const params = {
            'sms_template': formData.sms_template,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: params,
                url: `/incentives/topups/${formData.id}/smstemplate`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data } = await axios(config);
                dispatch('getIncentives');
            } catch(err) {
                console.log("Ajax Error",err);
            }
            commit('SET_LOADING',false,{ root: true });
        } else {
            router.push('/login');
        }
    },

    async deleteIncentiveTopups ({ dispatch }, id) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `/incentives/topups/${id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                if (router.currentRoute.path !== '/incentive/topup') {
                    router.push('/incentive/topup');
                } else {
                    dispatch('getIncentiveTopups');
                }
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async sendRequestIncentiveTopups ({ dispatch, commit }, id) {
        commit('SET_LOADING',true,{ root: true });
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                url: `/incentives/topups/${id}/sendrequest`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                commit('SET_LOADING',false,{ root: true });
                dispatch('getIncentiveTopupsDetail',router.currentRoute.params.id);
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    // case 423:
                    //     alert(error.response.data.message);
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },
    
    async getIncentiveTopupsDetail ({ commit }, incentive_topup_id) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `incentives/topups/${incentive_topup_id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try {
                let { data } = await axios(config);
                commit('SET_INCENTIVE_TOPUPS_DETAIL',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true })
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

}

const mutations = {
    SET_INCENTIVES : (state, payload) => {
        state.incentives = payload
      },
    SET_INCENTIVE_TOPUPS : (state, payload) => {
        state.incentive_topups = payload
    },
    SET_INCENTIVE_TOPUPS_DETAIL : (state, payload) => {
        state.incentive_topups_detail = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
