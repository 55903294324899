import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    // incentives: [],
}

const getters = {
    // allIncentives: (state) => state.incentives,
}

const actions = {

    async sendOrderManualTopup ({ commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        //** Prepare to post data */
        const params = {
            "order_no" : formData.order_no,
            "amount" : parseInt(formData.amount),
            "type" : formData.transaction_type,
            "note" : formData.note,
            "sms_text" : formData.sms
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/transaction/manual`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                commit('SET_LOADING',false,{ root: true });
                alert("Manual topup request for order no " + formData.order_no + " has added to Zendesk.");
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    // case 423:
                    //     alert(error.response.data.message);
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async sendOrderManualTopupNew ({commit }, formData) {

        commit('SET_LOADING',true,{ root: true });
        console.log("formdata =>> " + JSON.stringify(formData))

        function uploadAWS(value) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                let binaryFile = ''

                reader.readAsArrayBuffer(value.file_data);
                reader.onload = async function(e) {
                    binaryFile = Buffer.from(reader.result);

                    const instance = await axios.create({
                        baseURL: value.url_upload,
                        timeout: 5000
                    });

                    instance.defaults.headers.common = {};
                      
                    let data = await instance.put(value.url_upload,binaryFile,{
                        headers: {
                          'Content-Type': value.file_data.type
                        }
                      })
                    console.log("put file =>> " + data)
    
                    resolve(value.path)
                }
            });
        }
          
        const promises = [];
        for (let i = 0 ; i < formData.image.length ; i++) {
            if(formData.image[i].file_data && formData.image[i].path && formData.image[i].url_upload) {
                promises.push(uploadAWS(formData.image[i]));
            }
        }
        
        let attachment_file = await Promise.all(promises)
            .then((results) => {
                console.log("All done", results);
                return results
            })
            .catch((e) => {
                console.log(e)
            });

        console.log("attachment =>> " + attachment_file)

        let type = ''
        if(formData.subject == '1')
            type ='claim_taxi'
        if(formData.subject == '2')
            type ='claim_parking'
        if(formData.subject == '3')
            type ='claim_error_delivery_adjust'
        if(formData.subject == '4')
            type ='claim_cancel_order'
        if(formData.subject == '5')
            type ='claim_cancel_order'
        if(formData.subject == '6')
            type ='claim_error_delivery_manual_order'
        if(formData.subject == '7')
            type ='claim_error_delivery_manual_order'
        if(formData.subject == '8')
            type ='claim_error_delivery'
        if(formData.subject == '9')
            type ='claim_error_food'
        if(formData.subject == '10')
            type ='claim_error_food'
        if(formData.subject == '11')
            type ='claim_delivery_big_order'

        // ** Prepare to post data */
        const params = {
            "assignee" : formData.assignee,
            "form" : formData.form,
            "tags" : formData.tags,
            "subject" : formData.subject,
            "order_no" : formData.order_no,
            "amount" : parseInt(formData.amount),
            "type" : type,
            "note" : formData.note,
            "sms_text" : formData.sms,
            "remark" : formData.remark,
            "sms" : formData.sms,
            "attachment" : attachment_file,
        };


        const token = helpers.getToken();

        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/transaction/manual_new`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                commit('SET_LOADING',false,{ root: true });
                alert("Manual topup request for order no " + formData.order_no + " has added to Zendesk.");
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    // case 423:
                    //     alert(error.response.data.message);
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async getUploadAWSUrl({ commit },formData) {
        const filename = formData.image[formData.current_img].file_data.name

        let params = {
            filename : filename
        }

        const token = helpers.getToken();

        const config = { 
            method: 'POST',
            data: params,
            url: `/order/${formData.order_no}/attachment`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",
            }
        };

        let { data } = await axios(config);

        return data
    },

    async updateZEEKInfo({ commit }, formData) {
        const params = {
            'employee_id': formData.employee_id,
            'skill_set' : formData.skill_set,
            'position_code' : formData.position_code
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: params,
                url: `/order/${formData.order_no}/update_zeek_rider_info`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                commit('SET_LOADING',false,{ root: true });
                alert("Update Zeek for order no " + formData.order_no);
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async sendEmployeeManualTopup ({ commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        //** Prepare to post data */
        const params = {
            "employee_no" : formData.employee_no,
            "amount" : parseInt(formData.amount),
            "type" : formData.transaction_type,
            "note" : formData.note,
            "sms_text" : formData.sms
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/transaction/manual_employee`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                commit('SET_LOADING',false,{ root: true });
                alert("Manual topup request for employee no " + formData.employee_no + " has added to Zendesk.");
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    // case 423:
                    //     alert(error.response.data.message);
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },

    async getCurrentPosition ({ commit } , order_no) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `webhook_order/detail/` + order_no,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                // commit('SET_USING_TRAININGS',data);
                return data
            } catch (err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async getDriverDetail ({ commit, dispatch }, id) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee/${id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                
                let { data } = await axios(config);
                return data
            } catch(err){
                console.log("Ajax Error",err);

            }
        } else {
            router.push('/login');
        }
    },

    
}

const mutations = {
    SET_INCENTIVES : (state, payload) => {
        state.incentives = payload
      },
    SET_INCENTIVE_TOPUPS : (state, payload) => {
        state.incentive_topups = payload
    },
    SET_INCENTIVE_TOPUPS_DETAIL : (state, payload) => {
        state.incentive_topups_detail = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
