// import * as types from './mutations-type';

export const mutations = {
    SET_DRIVERS : (state, payload) => {
        state.drivers = payload
    },
    SET_DRIVER_DETAILS (state, payload) {
        state.driver_details = payload
    },
    SET_PROVINCES (state, payload) {
        state.provinces = payload
    },
    SET_PROVINCES_ARRAY (state, payload) {
        payload.forEach(element => {
            state.provinces_array[element.ProvCode] = element.ProvName_TH
        });
    },
    SET_ZONES (state, payload) {
        state.zones = payload
    },
    SET_EQUIPMENTS (state, payload) {
        state.equipments = payload
    },
    SET_EMPLOYEE_EQUIPMENTS (state, payload) {
        state.employee_equipments = payload
    },
    SET_DRIVER_TRIPS_REPORT (state, payload) {
        state.driver_trips_report = payload
    },
    SET_DRIVER_TRANSACTIONS_REPORT (state, payload) {
        state.driver_transactions_report = payload
    },
    SET_DRIVER_RAITINGS_REPORT (state, payload) {
        state.driver_raitings_report = payload
    },
    SET_ALLOW_SEARCH (state, payload) {
        state.allow_search = payload
    },


};
