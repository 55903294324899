import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    // incentives: [],
    // incentive_topups: [],
    // incentive_topups_detail: []
}

const getters = {
    // allIncentives: (state) => state.incentives,
    // allIncentiveTopups: (state) => state.incentive_topups,
    // IncentiveTopupsDetail: (state) => state.incentive_topups_detail
}

const actions = {
    async getChannelManpower ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `manpower/report_channel_mapping`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                return data
                // console.log(data)
                // commit('SET_INCENTIVES',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async getTemplateManpower ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `manpower/report_template`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                return data
                // console.log(data)
                // commit('SET_INCENTIVES',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async getSnapshotManpower ({commit, report_date}) {
        return report_date
        // const token = helpers.getToken();
        // if(token){
        //     const config = { 
        //         method: 'GET',
        //         url: `manpower/report_template`,
        //         headers: {
        //             "Authorization": `Bearer ${token}`,
        //             "Content-Type": "application/json;charset=UTF-8",
        //         }
        //     };
        //     try {
        //         let { data } = await axios(config);
        //         return data
        //         // console.log(data)
        //         // commit('SET_INCENTIVES',data);
        //     } catch(err) {
        //         console.log("Ajax Error",err);
        //     }
        // } else {
        //     router.push('/login');
        // } 
    },

    async getReportManpower ({commit},params) {
        console.log("report_date : " + params.report_date)
        const token = helpers.getToken();
        let ids = params.template_ids.join(',')
        // console.log(ids)
        if(token){
            const config = { 
                method: 'GET',
                url: `manpower/reports?report_template_ids=`+ ids +`&report_date=`+ params.report_date +` `,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try {
                let { data } = await axios(config);
                return data
                // console.log(data)
                // commit('SET_INCENTIVES',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        } 
    }

}

const mutations = {
    // SET_INCENTIVES : (state, payload) => {
    //     state.incentives = payload
    //   },
    // SET_INCENTIVE_TOPUPS : (state, payload) => {
    //     state.incentive_topups = payload
    // },
    // SET_INCENTIVE_TOPUPS_DETAIL : (state, payload) => {
    //     state.incentive_topups_detail = payload
    // }
}

export default {
    state,
    getters,
    actions,
    mutations
}
