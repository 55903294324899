import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    users: [],
    user_roles: [],
    user_roles_array: [],
    user_routegroups: [],
    routegroups_array: [],
    user_routes: [],
    user_permissions: [],
    access_log: []
}

const getters = {
    allUsers: (state) => state.users,
    allUserRoles: (state) => state.user_roles,
    UserRolesArray: (state) => state.user_roles_array,
    allUserRoutegroups: (state) => state.user_routegroups,
    RoutegroupsArray: (state) => state.routegroups_array,
    allUserRoutes: (state) => state.user_routes,
    allUserPermissions: (state) => state.user_permissions,
    accessLogs: (state) => state.access_log
}

const actions = {
    async getUsers ({ commit, dispatch, state }) {
        var token = helpers.getToken();
        const config = { 
            method: 'GET',
            url: `user`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",
            }
        };

        commit('SET_LOADING_DATA_TABLE',true,{ root: true });
        try{
            let { data } = await axios(config);
            commit('SET_USERS',data);

            if(state.user_roles.length == 0){
                dispatch('getUserRoles');
            }

            commit('SET_LOADING_DATA_TABLE',false,{ root: true });

            } catch(err) {
                console.log("Ajax Error",err);
            }
    },

    async createUser ({ dispatch }, formData) {
        const params = {
            "email": formData.email,
            "password": formData.password,
            "fullname": formData.fullname,
            "user_role_id" : formData.user_role_id
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `user`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUsers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateUser ({ dispatch }, formData) {
        const params = {
            // "email": formData.email,
            // "password": formData.password,
            "fullname": formData.fullname,
            "user_role_id" : formData.user_role_id
        };
        if(formData.password && formData.password  !== '') {
            params.password = formData.password 
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `user/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUsers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteUser ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `user/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUsers');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    // -----------------------------------------------------------
    async getAccessLogs ({ commit }, options) {
        var token = helpers.getToken();
        
        let order = options.sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        let sort = options.sortBy.map(function (value) {
            return `${value}`;
        });


        const config = { 
            method: 'GET',
            url: `user/access_logs`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
            params : {
                "_page" : options.page || 1,
                "_limit" : options.itemsPerPage || 100,
                search_username : options.filter.username,
                search_url : options.filter.url,
                daterange : JSON.stringify(options.filter.date_range),
                "_sortby" : JSON.stringify(sort),
                "_order" : JSON.stringify(order),
            }
        };

        commit('SET_LOADING_DATA_TABLE',true,{ root: true });
        try{
            let { data } = await axios(config);
            commit('SET_ACCESS_LOGS',data);


            commit('SET_LOADING_DATA_TABLE',false,{ root: true });

        } catch(err) {
            console.log("Ajax Error",err);
        }
    },


    // -----------------------------------------------------------

    async getUserRoles ({ commit, state, dispatch  }) {

        var token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `user_role`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_USER_ROLES',data);

                var role_array = [];
                data.forEach(el => {
                    role_array[el.id] = el.name;
                });
                commit('SET_USER_ROLES_ARRAY',role_array);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createUserRole ({ dispatch }, formData) {
        const params = {
            "name": formData.name,
            "route_permission": formData.permission,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `user_role`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserRoles');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateUserRole ({ dispatch }, formData) {
        const params = {
            "name": formData.name,
            "route_permission": formData.permission,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `user_role/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserRoles');
            } catch(err) {
                switch(err.status){
                    case 423:
                        alert("Error: Duplicate Role Name.");
                        break;
                    default:
                        alert('Error '+ err.status + '  : ' + err.data.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    },

    async deleteUserRole ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `user_role/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserRoles');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    // -----------------------------------------------------------

    async getUserRoutegroups ({ commit, state, dispatch }) {
        var token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `routegroups`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_USER_ROUTEGROUPS',data);

                if(state.user_routes.length == 0){
                    dispatch('getUserRoutes');
                }
                if(state.user_permissions.length == 0){
                    dispatch('getUserPermissions');
                }
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });

            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createUserRoutegroup ({ dispatch }, formData) {
        const params = {
            "name": formData.name,
            "icon" : formData.icon,
            "parent_id" : formData.parent_id,
            "model" : formData.model,
            "show_nav" : formData.show_nav,
            "sort": parseInt(formData.sort),
            "route_name" : formData.routename,
            "route_path" : formData.routepath
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `routegroups`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateUserRoutegroup ({ dispatch }, formData) {
        var routes = [];
        formData.routes.forEach(element => {
            routes.push(element.id);
        });
        const params = {
            "name": formData.name,
            "icon" : formData.icon,
            "parent_id" : formData.parent_id,
            "model" : formData.model,
            "show_nav" : formData.show_nav,
            "sort": parseInt(formData.sort),
            "routes" : routes,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `routegroups/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                // dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteUserRoutegroup ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `routegroups/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                // dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
    // -----------------------------------------------------------

    async getUserRoutes ({ commit }) {
        var token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `user_route`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_USER_ROUTES',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createUserRoute ({ dispatch }, formData) {
        const params = {
            "permission_id": parseInt(formData.permission_id),
            "name": formData.route_name,
            "path" : formData.route_path,
            "routegroup_id" : formData.routegroup
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `user_route`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateUserRoute ({ dispatch }, formData) {
        const params = {
            "permission_id": parseInt(formData.permission_id),
            "name": formData.route_name,
            "path" : formData.route_path,
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `user_route/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                // dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async deleteUserRoute ({ dispatch }, formData) {
        console.log('delete',formData);
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `user_route/${formData.id}/${formData.routegroup.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                // dispatch('getUserRoutes');
                dispatch('getUserRoutegroups');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    // -----------------------------------------------------------

    async getUserPermissions ({ commit }) {
        var token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `user_permission`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_USER_PERMISSIONS',data);
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async createUserPermission ({ dispatch }, formData) {
        const params = {
            "name": formData.name,
            "sort": parseInt(formData.sort),
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `user_permission`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserPermissions');
            } catch(err) {
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },

    async updateUserPermission ({ dispatch }, formData) {
        const params = {
            "name": formData.name,
            "sort": parseInt(formData.sort),
        };
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'PUT',
                data: JSON.stringify(params),
                url: `user_permission/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data} = await axios(config);
                dispatch('getUserPermissions');
            } catch(err) {
                switch(err.status){
                    case 423:
                        alert("Permission Name has been existing.");
                        break;
                    default:
                        alert('Error '+ err.status + '  : ' + err.data.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    },

    async deleteUserPermission ({ dispatch }, formData) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `user_permission/${formData.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                dispatch('getUserPermissions');
            } catch(err) {
                switch(err.status){
                    case 403:
                        alert("Forbidden, Deleting this data is not allowed.");
                        break;
                    default:
                        alert('Error '+ err.status + '  : ' + err.data.message);
                        break;
                }
            }
        } else {
            router.push('/login');
        }
    },
}

const mutations = {
    SET_USERS : (state, payload) => {
        state.users = payload
    },
    SET_ACCESS_LOGS : (state, payload) => {
        state.access_log = payload
    },
    SET_USER_ROLES : (state, payload) => {
        state.user_roles = payload
    },
    SET_USER_ROLES_ARRAY : (state, payload) => {
        state.user_roles_array = payload
    },
    SET_USER_ROUTEGROUPS : (state, payload) => {
        state.user_routegroups = payload;

        state.user_routegroups.forEach(element => {
            state.routegroups_array[element.id] = {
                name: element.name,
                can: element.routes
            }
        });
    },
    SET_USER_ROUTES : (state, payload) => {
        state.user_routes = payload
    },
    SET_USER_PERMISSIONS : (state, payload) => {
        state.user_permissions = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
