export const getters = {
  allDrivers: (state) => state.drivers,
  allowSearch: (state) => state.allow_search,
  DriverDetails: (state) => state.driver_details,
  allProvinces: (state) => state.provinces,
  ProvincesArray: (state) => state.provinces_array,
  allZones: (state) => state.zones,
  driverTripsReport: (state) => state.driver_trips_report,
  driverTransactionsReport: (state) => state.driver_transactions_report,
  driverRaitingsReport: (state) => state.driver_raitings_report,
  depositStatus: (state) => state.deposit_status,
  equipmentList: (state) => state.equipments,
  employeeEquipment: (state) => state.employee_equipments,
  equipmentFilter: (state) => {
    return state.equipments.filter((item) => {
      return item.is_available === true;
    });
  }
};
