import axios from 'axios';
import helpers from '@/helpers';
import router from '@/routes';

const state = {
    transaction_status : [
        { value: '0', text : 'Pending'},
        { value: '1', text : 'Success'},
        { value: '-1', text : 'Verify Fail'},
        { value: '-2', text : 'Notify Fail'},
    ],
    incentive_topups_status : [
        { value: 'pending', text : 'Pending'},
        { value: 'approve_request', text : 'Approve Request'},
        { value: 'approved', text : 'Approved'},
        { value: 'rejected', text : 'Rejected'},
    ],
}

const getters = {
    TransactionStatus: (state) => state.transaction_status,
    IncentiveTopupsStatus: (state) => state.incentive_topups_status
}

const actions = {
    

}

const mutations = {
   
}

export default {
    state,
    getters,
    actions,
    mutations
}
