<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <!-- Main List For Loop -->
        <template v-for="item in MainNav" router :to="item.route">
          <!-- List Heading -->
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <!-- List Group If has children-->
          <v-list-group v-else-if="item.children" :key="item.name" v-model="item.model" :prepend-icon="item.icon"
            :append-icon="item.model ? item['iconsub-active'] : item['iconsub-active']">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Child Items For Loop -->
            <v-list-item v-for="(child, i) in item.children" :key="i" router :to="child.route">
              <!-- Children Icon -->
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <!-- Child Title -->
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Normal Item -->
          <v-list-item v-else :key="item.name" router :to="item.route">
            <!-- Item Icon -->
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <!-- Item Title -->
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
      </v-list>
    </v-navigation-drawer>

    <v-snackbar v-model="setAlert" :timeout="3000" top color="light-blue lighten-5" class="primary--text">
      <span>{{ alertMessage }}</span>
      <v-btn depressed color="primary" dark @click="setAlert = false" x-small="">close</v-btn>
    </v-snackbar>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title style="width: 400px" class="ml-0 pl-4 gap-4">
        <span class="hidden-sm-and-down">Driver Management</span>
        <span class="text-body-2 text-caption ml-4">version {{ app_version }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <span class="username">{{ userProfile.fullname }}</span>
            <v-btn text icon class="mx-2" v-on="on">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </div>
        </template>

        <v-list>
          <v-list-item @click="editProfile()">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="dialog" max-width="500px">
        <!-- User Profile Modal Content -->
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>

          <v-card-text>
            <v-form v-model="form_valid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.fullname" :rules="[validate_rules.required]" label="Name *"
                      :disabled="isEdit">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.email" :disabled="userType != 'DM'"
                      :rules="[validate_rules.required, validate_rules.email]" label="Email *"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.password" label="Password *" :rules="[validate_rules.password]"
                      :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                      v-if="userType == 'DM'"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.selected_userrole" :disabled="isEdit" label="Role"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save" v-if="userType == 'DM'">Save</v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>
    </v-app-bar>
    <v-content>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-content>
    <Loader />
  </v-app>
</template>

<script>
import Loader from '@/components/common/loading.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    source: String
  },
  components: {
    Loader
  },
  data: () => ({
    dialog: false,
    drawer: null,
    isEdit: true,
    showPassword: false,
    editedIndex: -1,
    editedItem: {
      id: -1,
      fullname: '',
      email: '',
      password: '',
      user_role_id: 1,
      selected_userrole: null
    },
    userType: 'DM',
    form_valid: false,
    validate_rules: {
      required: v => !!v || 'This field is required',
      min: v => v.length >= 8 || 'Min 8 characters',
      email: v => /.+@.+\..+/.test(v) || 'Valid email pattern is required',
      password: v => {
        if (v) {
          return (v.length >= 8 || 'Min 8 characters');
        } else {
          return true
        }
      }
    },
    app_version: process.env.PACKAGE_VERSION
  }),
  methods: {
    ...mapActions(['setLoading', 'setAlertState', 'logout', 'getUserProfile', 'updateUserProfile']),
    editProfile() {
      this.editedItem = Object.assign({}, this.userProfile)
      this.editedItem.selected_userrole = this.editedItem.user_role.name
      this.editedItem.password = ''
      this.dialog = true
    },

    save() {
      if (this.form_valid) {
        this.updateUserProfile(this.editedItem);
        this.dialog = false;
      }
    },
  },
  computed: {
    ...mapGetters(['loadingState', 'alertState', 'alertMessage', 'alertMessage', 'userProfile', 'userNav', 'userCan']),

    MainNav() {
      // var res = this.userNav ? this.userNav : [];
      return this.userNav || [];
    },

    setAlert: {
      // getter
      get: function () {
        return this.alertState
      },
      // setter
      set: function (newValue) {
        this.setAlertState(newValue);
      }
    }
  },
  created() {
    this.getUserProfile();
    this.userType = localStorage.getItem('auth_type');
  }

}
</script>
<style>
.content-wrap {
  padding: 30px 20px;
}

.w-0 {
  width: 0px
}

.w-100 {
  width: 100px
}

.w-200 {
  width: 200px
}

.username {
  padding: 12px 0;
}

.border-right {
  border-right: 1px solid #ddd;
  margin-right: 20px
}

.v-data-table.text-small td {
  font-size: 11px !important;
  height: 40px !important;
}

.v-data-table.text-small td .v-label {
  font-size: 12px !important;
}

.v-label,
.v-text-field input,
.v-select__selections {
  font-size: 14px;
}

.v-icon.v-icon {
  font-size: 15px;
}

.v-select__selections {
  height: 32px !important;
  overflow: hidden !important;
}

.v-list-item {
  min-height: 40px;
}

.v-list-item__title {
  font-size: 14px;
  line-height: 1;
}

.hassep {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.search-tools .v-label,
.search-tools .v-text-field input,
.search-tools .v-select__selections {
  font-size: 12px;
}

/* .search-tools .v-list-item--dense, 
.search-tools .v-list--dense .v-list-item {
  min-height: 30px !important;
} */
.search-tools>.v-list-item--dense,
div[role=listbox] .v-list-item {
  min-height: 35px;
}

div[role=listbox] .v-list-item__content {
  padding: 8px 0;
}

div[role=listbox] .v-list-item__title {
  font-size: 12px;
  line-height: 16px;
}

div[role=listbox] .v-list-item .v-list-item__action {
  margin-right: 5px;
}

.tags_input div[role=combobox] .v-select__selections {
  min-height: 32px !important;
  height: auto !important;
  padding: 40px 0 10px !important;
}

form {
  width: 100%
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  /* add your IE10-IE11 css here */

  .content-wrap {
    width: calc(100vw - 300px);
  }

  .v-data-footer__select .v-input__slot {
    width: 75px;
  }

  .v-data-footer__select {
    margin-right: 200px !important;
  }

  .row.expand-info div {
    display: block !important;
  }
}
</style>