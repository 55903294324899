<template>
  <v-container fluid fill-height>
    <v-layout column justify-center>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="form_valid" @keyup.native.enter="form_valid && submit($event)">
                <v-text-field label="Email" name="email" type="email" v-model="form.email"
                  :rules="validate_rules.username" prepend-icon="mdi-mail"></v-text-field>
                <v-text-field label="Password" name="password" :type="showPassword ? 'text' : 'password'"
                  v-model="form.password" :rules="validate_rules.password" prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"></v-text-field>
                <v-select v-model="form.auth_type" :items="auth_method" item-text="name" item-value="abbr"
                  label="Auth Method" prepend-icon="mdi-domain" single-line></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary" @click.prevent="submit">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

      </v-layout>
      <v-text>
        Version: {{ app_version }}
      </v-text>
    </v-layout>
  </v-container>


</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'login',

  data: () => ({
    form: {
      email: '',
      password: '',
      auth_type: 'DM'
    },
    auth_method: [
      { name: "Command Center", abbr: "DM" },
      { name: "Redbook", abbr: "REDBOOK" }
    ],
    form_valid: false,
    validate_rules: {
      username: [
        v => !!v || 'Please file your email',
        // v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      password: [
        v => !!v || 'Please file your password',
      ]
    },
    showPassword: false,
    app_version: process.env.PACKAGE_VERSION
  }),

  methods: {
    ...mapActions(['login']),

    submit() {
      this.login(this.form);
    }
  }
};
</script>