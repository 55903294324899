import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

const state = {
  drivers: [],
  driver_details: [],
  allow_search: true,
  zones:[],
  provinces: [],
  provinces_array: [],
  driver_trips_report: null,
  driver_transactions_report: null,
  driver_raitings_report: null,
  deposit_status : [
    { id: 0, text: 'Not paid' }, 
    { id: 1, text: 'Paid'},
    { id: 9, text: 'Paid (200 THB / Installment)'},
    { id: 3, text: 'Paid (500 THB / Installment)'},
    { id: 4, text: 'Paid (1,000 THB / Installment)'},
    { id: 5, text: 'Paid (2,000 THB/ Installment)'},
    { id: 6, text: 'Paid (500 THB / Non-Installment)'},
    { id: 7, text: 'Paid (1,000 THB / Non-Installment)'},
    { id: 8, text: 'Paid (2,000 THB/ Non-Installment)'},
    { id: 2, text: 'Refund'}
  ],
  equipments: [],
  employee_equipments : []
};

export default {
  state,
  mutations,
  actions,
  getters
};