import axios from 'axios';
import router from '@/routes';
import helpers from '@/helpers'
import _ from 'lodash';

const state = {
    evidences: null,
    profile_image: {
        title: 'โปรไฟล์',
        slug: 'img-profile',
        src : '/images/unnamed.jpg',
        image_path : null,
    },
    evd_images: [
        { 'slug' : 'img-profile', 'name' : 'โปรไฟล์', 'is_evd' : false , 'sort' : 0 },
        { 'slug' : 'img-idcard', 'name' : 'บัตรประชาชน', 'is_evd' : true , 'sort' : 1 },
        { 'slug' : 'img-license', 'name' : 'ใบขับชี่', 'is_evd' : true , 'sort' : 2},
        { 'slug' : 'img-compulsory', 'name' : 'พ.ร.บ.', 'is_evd' : true , 'sort' : 3},
        { 'slug' : 'img-mc', 'name' : 'มอเตอร์ไซค์', 'is_evd' : true , 'sort' : 4},
    ]
}

const getters = {
    allEvidences: (state) => state.evidences,
    evdImages: (state) => state.evd_images,
    profileImage: (state) => state.profile_image
}

const actions = {
    async getEvidences ({ dispatch } , id) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee/evidence/${ id }`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                var {data, status} = await axios(config);   
                
                if(status == 200 & data[id].length > 0) {
                    const imgObj = data[Object.keys(data)[0]];
                    try{
                        dispatch('renderEvdImages',imgObj); 
                    } catch(err){
                        console.log("Error render image," + err.status);
                    } 
                }
            } catch(err){
                // Need some more work
                console.log("Ajax Error",err);
            }
            
        } else {
            router.push('/login');
        }
    },

    renderEvdImages ({commit}, images) {
        var evdImages = [];
        _.forEach(state.evd_images, function(img) {
            if(img.is_evd){
                var evd = _.filter(images, ['name', img.slug]);
                evdImages.push({
                                title: img.name,
                                slug: evd[0].name,
                                src : 'data:image/png;base64,' + evd[0].image_data,
                                image_path : evd[0].image_path,
                                created_user : evd[0].created_user
                            });
            } else {
                var profile = _.filter(images, ['name', 'img-profile']);
                if(profile.length > 0){
                    commit('SET_PROFILE_IMG',{
                        title: img.name,
                        slug: profile[0].name,
                        src : 'data:image/png;base64,' + profile[0].image_data,
                        image_path : profile[0].image_path,
                        created_user : profile[0].created_user
                    });
                }
            }
        });
        commit('SET_EVIDENCES',evdImages);
        commit('SET_LOADING',false,{ root: true });
    },

    uploadImage ({ dispatch } , data) {

        var fileToLoad = data.file;
        var fileReader = new FileReader();
        // var imgFolder = setLocalStorageItem('imgFolder');

        //** Convert image file to base 64 **/
        fileReader.onload = function(fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result; // <--- data: base64
            var imgBase64 = srcData.split(",")[1];

            //** Prepare to post image */
            const params = {
                'folder': data.folder,
                'filename' : data.slug,
                'img' : imgBase64
            };
            dispatch('uploadAjax', params)
        }
        fileReader.readAsDataURL(fileToLoad);
    },

    async uploadAjax ({dispatch,commit}, params){
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `employee/upload_image`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING',true,{ root: true });
            try{
                let { data } = await axios(config);
                dispatch('getEvidences',router.currentRoute.params.id);
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login')
        }
    },
    resetProfileImage ({commit}){
        commit('SET_PROFILE_IMG',{
            title: 'โปรไฟล์',
            slug: 'img-profile',
            src : '/images/unnamed.jpg',
            image_path : null,
        });
    }

}

const mutations = {
    SET_EVIDENCES (state, payload) {
        state.evidences = payload
    },
    SET_PROFILE_IMG (state, payload) {
        state.profile_image = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
};

